'use client';
/* eslint-disable @next/next/no-img-element */
import {
  ForwardedRef,
  forwardRef,
  ReactElement,
  SyntheticEvent,
  useCallback,
} from 'react';

import Dynamic from './Dynamic/Dynamic';
import styles from './Image.module.css';
import { ImageFit, ImageProps } from './Image.types';
import Preload from './Preload/Preload';

/**
 * Image component for assets coming from Sanity. It will instanciate an `<img>` element with all the
 * necessary attributes (like `alt`), and then pass it down to the rendering components, `<Preload>`
 * or `<Dynamic>`. Those components will clone it in order to their own specific attributes, like `src`.
 * @param source CMSImage
 * @param {boolean} [contain] Similar to CSS contain (not compatible with `fixedAspectRatio`)
 * @param {boolean} [preload] If the image should be preloaded instead of lazy-loaded, Requires `sizes`
 * @param {ImageSizes} [sizes] Dimensions of the image per breakpoint - Required if `preload` is enabled
 * @param {boolean} [fixedAspectRatio] Will disable the image generation optimization of incrementing the height.
 * Only use when the aspect ratio of the image is fixed across all breakpoint to have more precision
 * @param {number} [quality] The quality of the image – 0-100
 * @param {number} [dpr] Override the DPR value of the image
 * @param {number} [blur] Blurs the image on the Image side
 * @param {boolean} [isDisplayed] Overrides the default automatic lazy loading behavior
 * @param {Function} [onReady] Callback firing on image load - Receives the HTML element as a parameter
 * @param {string} [className] The className added to the `<figure>` element
 * @param {RefObject} [ref] The ref pointing to the `<figure>` element
 * @returns A figure element containing an image
 * @example
 * <Image source={mySource} className={myStyle} />
 * <Image source={mySource} className={myStyle} preload={true} sizes={{base: {width: 100} [...]}} />
 */
const Image: (
  props: ImageProps,
  ref: ForwardedRef<HTMLElement>,
) => ReactElement = (
  {
    source,
    contain,
    fixedAspectRatio,
    preload,
    sizes,
    className,
    quality = 80,
    isDisplayed,
    onReady,
    autoResize,
    animated,
    blur,
    dpr,
  },
  ref,
) => {
  // We return early if the source is null or undefined
  if (source == null || source.asset == null) {
    if (process.env.VERCEL_ENV === 'production') {
      return <></>;
    }
    console.warn('Image not found', source, className);
    return <pre>Image not found</pre>;
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const onLoad = useCallback(
    (event: SyntheticEvent<HTMLImageElement>) => {
      if (onReady) {
        onReady(event.target as HTMLImageElement);
      }
    },
    [onReady],
  );

  const Img = (
    <img
      crossOrigin="anonymous"
      alt={source.alt}
      className={styles.image}
      onLoad={onLoad}
    />
  );

  if (preload) {
    return (
      <Preload
        source={source}
        sizes={sizes}
        quality={quality}
        className={className}
        contain={contain}
        ref={ref}
      >
        {Img}
      </Preload>
    );
  } else {
    let fitProps: ImageFit = {};
    if (contain) {
      fitProps = { contain: true };
    } else if (fixedAspectRatio) {
      fitProps = { fixedAspectRatio: true };
    }
    return (
      <Dynamic
        source={source}
        quality={quality}
        isDisplayed={isDisplayed}
        className={className}
        {...fitProps}
        ref={ref}
        autoResize={autoResize}
        animated={animated}
        blur={blur}
        dpr={dpr}
      >
        {Img}
      </Dynamic>
    );
  }
};

export default forwardRef(Image);
