import { CMSImage } from '~/components/atoms/Image/Image.types';
import { CMSSvg } from '~/components/atoms/Svg/Svg.types';
import {
  CMSGroupBase,
  MoleculeBase,
} from '~/components/organisms/ModularComponent/ModularComponent.types';

// CMS props used for data coming from Sanity
export type CMSGraphicProps = CMSGroupBase & {
  name: string;
  source: CMSImage | CMSSvg;
};

export type GraphicProps = MoleculeBase & {
  name: string;
  source: CMSImage | CMSSvg;
  isInView?: boolean;
  disableAutoSize?: boolean;
};

export const GRAPHIC_IMAGE_MULTIPLIER = 4;
