import { ImageUrlBuilder } from '@sanity/image-url/lib/types/builder';

import { CMSImage } from '../Image.types';

const getContainedImageURL = (source: CMSImage, URLObject: ImageUrlBuilder) => {
  let croppedLeft = 0;
  let croppedTop = 0;
  let croppedWidth = source.asset.width;
  let croppedHeight = source.asset.height;

  if (source.crop) {
    croppedLeft = Math.round(source.asset.width * source.crop.left);
    croppedTop = Math.round(source.asset.height * source.crop.top);
    croppedWidth = Math.round(
      source.asset.width -
        (source.asset.width * source.crop.left +
          source.asset.width * source.crop.right),
    );
    croppedHeight = Math.round(
      source.asset.height -
        (source.asset.height * source.crop.top +
          source.asset.height * source.crop.bottom),
    );
  }

  return URLObject.rect(croppedLeft, croppedTop, croppedWidth, croppedHeight)
    .fit('fill')
    .bg('0000');
};

export default getContainedImageURL;
