import { SharedIntersectionObserverOptions } from './SharedIntersectionObserver.types';

/**
 * A singleton intersection observer, which will reuse observers based on their options
 * If an observer is requested with options that are not already used, a new one will be created automatically
 * @class ClientSharedIntersectionObserver
 * @typedef {ClientSharedIntersectionObserver}
 */
class ClientSharedIntersectionObserver {
  private observers: Map<string, IntersectionObserver>;
  private elementCallbacks: Map<
    Element,
    { callback: (rect: DOMRect | null) => void; detectExit?: boolean }
  >;

  constructor() {
    this.observers = new Map();
    this.elementCallbacks = new Map();
  }

  // Method to convert the options object to a string
  private stringifyOptions(options: IntersectionObserverInit) {
    return JSON.stringify(
      Object.entries(options).sort(([key1], [key2]) =>
        key1.localeCompare(key2),
      ),
    );
  }

  // Method to get an intersection observer instance for the given options
  private getObserver(options: IntersectionObserverInit) {
    // Convert the options object to a string
    const stringifiedOptions = this.stringifyOptions(options);

    // Check if we already have an observer instance with the given options
    let observer = this.observers.get(stringifiedOptions);

    if (!observer) {
      // Create a new observer instance with the given options
      observer = new IntersectionObserver(this.handleIntersection, options);

      // Store the observer instance in the map
      this.observers.set(stringifiedOptions, observer);
    }

    return observer;
  }

  // Method to handle intersection events
  private handleIntersection = (entries: IntersectionObserverEntry[]) => {
    entries.forEach((entry) => {
      const elementCallback = this.elementCallbacks.get(entry.target);
      if (elementCallback && entry.isIntersecting) {
        elementCallback.callback(entry.boundingClientRect);
      } else if (!entry.isIntersecting && elementCallback?.detectExit) {
        elementCallback.callback(null);
      }
    });
  };

  /**
   * Subscribe an element to a shared intersection observer using the options given.
   * If no intersection observer matches the given options, a new one will be
   * automatically created
   * @param element The element we want to observe
   * @param callback The callback to execute when intersecting
   * @param options The options for the observer
   * @example
   * const myCallback = useCallback((myElementRect) => console.log(myElementRect), []);
   * ShareIntersectionObserver.subscribe($myImage, myCallback, { rootMargin: '200% 0%' })
   */
  subscribe(
    element: Element,
    callback: (rect: DOMRect | null) => void,
    options: SharedIntersectionObserverOptions,
  ) {
    this.elementCallbacks.set(element, {
      callback,
      detectExit: options.detectExit,
    });
    const observer = this.getObserver(options);
    observer.observe(element);
  }

  /**
   * Unsubscribe an element. It will remove it from the class and unobserve it.
   * @param element The element we subscribed
   * @param options The options we used when subscribing
   */
  unsubscribe(element: Element, options: SharedIntersectionObserverOptions) {
    // Remove the callback for the given element
    this.elementCallbacks.delete(element);

    // Convert the options object to a string
    const stringifiedOptions = this.stringifyOptions(options);

    // Get the observer instance for the given options
    const observer = this.observers.get(stringifiedOptions);

    // Stop observing the given element if we have an observer
    if (observer) {
      observer.unobserve(element);
    }
  }
}

class ServerSharedIntersectionObserver {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor() {}
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  subscribe() {}
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  unsubscribe() {}
}

const SharedIntersectionObserver =
  typeof window !== 'undefined'
    ? new ClientSharedIntersectionObserver()
    : new ServerSharedIntersectionObserver();

export default SharedIntersectionObserver;
