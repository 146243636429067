import { SanityImageSource } from '@sanity/asset-utils';
import imageUrlBuilder from '@sanity/image-url';

/**
 * Creates the image builder from Sanity based on the environement variables
 * @param source The image source from sanity
 * @returns Sanity image builder
 */
const urlFor = (source: SanityImageSource) => {
  const builder = imageUrlBuilder({
    projectId: process.env.NEXT_PUBLIC_SANITY_STUDIO_API_PROJECT_ID || '',
    dataset: process.env.NEXT_PUBLIC_SANITY_STUDIO_API_DATASET || '',
  });

  return builder.image(source);
};

export default urlFor;
