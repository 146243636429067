import { CMSImage } from '../Image.types';

/**
 * Takes a source as input to compute the correct aspect ratio depending on an eventual crop.
 * @param  source The image source from Sanity
 * @returns Aspect ratio as a number
 */
const getAspectRatio = (source: CMSImage) => {
  return source.crop
    ? (source.asset.width -
        (source.asset.width * source.crop.left +
          source.asset.width * source.crop.right)) /
        (source.asset.height -
          (source.asset.height * source.crop.top +
            source.asset.height * source.crop.bottom))
    : source.asset.aspectRatio;
};

export default getAspectRatio;
