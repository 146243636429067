import { SanityImageCrop, SanityImageHotspot } from '@sanity/asset-utils';

// TODO: Try to see if we can infer the keys from the dynamic breakpoint tokens
export type breakpointName = 'sm' | 'md' | 'lg' | 'xl';
export type ImageSizes =
  | Record<
      breakpointName,
      {
        width: number;
        height?: number;
      }
    >
  | {
      width: number;
      height?: number;
    };

export type CMSAsset = {
  _id: string;
  darkVibrantBackground: string;
  dominantBackground: string;
  darkMutedBackground: string;
  lightMutedBackground: string;
  height: number;
  width: number;
  aspectRatio: number;
  isOpaque: boolean;
  lqip?: string;
};

export type CMSImage = {
  _key?: string;
  // TODO: find a way to not have "type" & "mediaType" since their are kinda doing the same thing
  // Used for Graphic to differenciate svg / image
  type: 'image';
  alt: string;
  crop: SanityImageCrop;
  hotspot: SanityImageHotspot;
  // Used for Media to differenciate video / image
  mediaType: 'image';
  asset: CMSAsset;
};

export type ImageProps = {
  source: CMSImage;
  className?: string;
  quality?: number;
  onReady?: ($element: HTMLImageElement) => void;
  dpr?: number;
  blur?: number;
} & (
  | {
      preload: true;
      sizes: ImageSizes;
      isDisplayed?: never;
      autoResize?: never;
      animated?: never;
      fixedAspectRatio?: never;
    }
  | {
      preload?: never;
      autoResize?: false;
      sizes?: never;
      isDisplayed?: boolean;
      animated?: boolean;
    }
) &
  ImageFit;

export type ImageFit =
  | {
      fixedAspectRatio: true;
      contain?: never;
    }
  | {
      fixedAspectRatio?: never;
      contain: true;
    }
  | {
      fixedAspectRatio?: undefined;
      contain?: undefined;
    };

export const MAX_DPR = 2;

export const MAX_WIDTH = 3840;

export const QUALITY_CUSHION = 1;
