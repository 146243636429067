'use client';
import { breakpoints } from '@frameio-bs/tokens';
import Head from 'next/head';
import { cloneElement, ForwardedRef, forwardRef, ReactElement } from 'react';

import { cn } from '~/utils';

import parentStyles from '../Image.module.css';
import {
  breakpointName,
  MAX_DPR,
  MAX_WIDTH,
  QUALITY_CUSHION,
} from '../Image.types';
import { getAspectRatio, getContainedImageURL, urlFor } from '../utils';
import styles from './Preload.module.css';
import { PreloadProps } from './Preload.types';

/**
 * This component will generate the HTML necessary to preload the image, with the
 * responsive being handled by having different `<source>` elements with different
 * `srcset` based on our breakpoints, and the sizes provided as a prop. The preloading
 * will happen by appending `<link>` elements to the `<head>` of the page, respecting
 * the media queries set for the `<source>` elements.
 * If `sizes` contain only one size (`{width: number, height: number}`) then only
 * the `<img>` element is generated in the `<picture>`.
 * @returns A `<figure>` element containing a `<picture>` element with `<sources>` in
 * it, as well as the cloned `<img>` element.
 */
const Preload = (
  {
    children,
    sizes,
    quality,
    source,
    className,
    contain,
    cover,
    blur,
    dpr: dprOverride,
  }: PreloadProps,
  ref: ForwardedRef<HTMLElement>,
) => {
  let baseURL = urlFor(source).quality(quality).auto('format');

  const hasUniqueSize = 'width' in sizes;

  if (contain) {
    baseURL = getContainedImageURL(source, baseURL);
  }

  const fallbackSrcSet = Array(MAX_DPR)
    .fill('')
    .map((a, dpr) => {
      // maxWidth is deprecated in favor of a different fit value,
      // but have to test if it works with contain
      let srcSetObject = baseURL.dpr(
        dprOverride || (dpr + 1) * QUALITY_CUSHION,
      );
      if (hasUniqueSize) {
        if ('width' in sizes) {
          srcSetObject = srcSetObject.width(sizes.width);
        }
        if ('height' in sizes && typeof sizes.height === 'number') {
          srcSetObject = srcSetObject.height(sizes.height);
        }
      } else {
        srcSetObject = srcSetObject.width(MAX_WIDTH);
      }

      if (blur) {
        srcSetObject.blur(blur);
      }

      return `${srcSetObject.url()} ${dpr + 1}x`;
    })
    .join(',');

  const img = cloneElement(children, {
    srcSet: fallbackSrcSet,
    className: cn(
      children.props.className,
      styles.image,
      (cover || contain) && styles.cover,
    ),
  });

  const href = baseURL
    .width(MAX_WIDTH)
    .dpr(dprOverride || QUALITY_CUSHION)
    .url();

  const Sources: ReactElement<HTMLSourceElement>[] = [];

  const Links = [];

  if (!hasUniqueSize) {
    breakpoints.map((breakpoint, index) => {
      if (
        !('width' in sizes) &&
        breakpoint.name in sizes &&
        breakpoints[index + 1]
      ) {
        const size = sizes[breakpoint.name as breakpointName];

        let height: number;

        if (size.height) {
          height = size.height;
        } else {
          height = Math.round(size.width / getAspectRatio(source));
        }

        const media = `(min-width: ${
          breakpoints[index].min
        }px) and (max-width: ${breakpoints[index + 1].min + 1}px)`;

        const srcSet = Array(MAX_DPR)
          .fill('')
          .map((_, dpr) => {
            return `${baseURL
              .width(size.width)
              .height(height)
              .dpr(dprOverride || (dpr + 1) * QUALITY_CUSHION)
              .url()} ${dpr + 1}x`;
          })
          .join(', ');

        Sources.push(
          <source media={media} key={href + media} srcSet={srcSet} />,
        );
        Links.push(
          <link
            rel="preload"
            as="image"
            href={href}
            key={href + media}
            media={media}
            imageSrcSet={srcSet}
            crossOrigin="anonymous"
          />,
        );
      }
    });
  }

  Links.push(
    <link
      rel="preload"
      as="image"
      href={href}
      key={href}
      media={`(min-width: ${
        hasUniqueSize ? 0 : breakpoints[breakpoints.length - 1].min + 1
      }px)`}
      imageSrcSet={fallbackSrcSet}
      crossOrigin="anonymous"
    />,
  );

  return (
    <figure
      className={cn(className, parentStyles.wrapper, styles.wrapper)}
      ref={ref}
    >
      <picture>
        {Sources}
        {img}
      </picture>
      <Head>{Links}</Head>
    </figure>
  );
};

export default forwardRef(Preload);
