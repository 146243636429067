import { ReactElement } from 'react';

import { CMSImage, ImageFit } from '../Image.types';

export type DynamicProps = {
  children: ReactElement<HTMLImageElement>;
  quality: number;
  source: CMSImage;
  isDisplayed?: boolean;
  className?: string;
  autoResize?: false;
  animated?: boolean;
  blur?: number;
  dpr?: number;
} & ImageFit;

export const DEFAULT_OBSERVER_OPTIONS = { rootMargin: '200% 0%' };
