import 'core-js/features/array/at';
// TODO: share same utilities file as cms/utils/getSvgDimensions somehow?

function getSvgDimensions(markupString: string) {
  // option 1: extract dimensions from width & height attributes if they have no units or if the unit is px
  const widthRegex = /width="(\d+\.\d+?)(?:px)?"/;
  const widthValue = markupString.match(widthRegex)?.at(-1);

  const heightRegex = /height="(\d+\.\d+?)(?:px)?"/;
  const heightValue = markupString.match(heightRegex)?.at(-1);

  if (widthValue && heightValue) {
    const width = parseFloat(widthValue ?? '0');
    const height = parseFloat(heightValue ?? '0');
    return { width, height };
  }

  // option 2: use viewbox
  // width & height have the same values as viewbox
  // svgo also strips out dimensions attributes to favor viewbox

  const viewBoxRegex = /viewBox="([^"]*)"/;
  const viewBox = markupString.match(viewBoxRegex)?.at(-1);
  const width = parseFloat(viewBox?.split(' ').at(2) ?? '0');
  const height = parseFloat(viewBox?.split(' ').at(3) ?? '0');

  return { width, height };
}

export default getSvgDimensions;
