'use client';
import NextLink from 'next/link';
import { ForwardedRef, forwardRef } from 'react';

import {
  CMSLinkProps,
  LinkProps,
  SAME_TAB_EXTERNAL_DOMAINS,
} from './Link.types';

const Link = (props: LinkProps, ref: ForwardedRef<HTMLElement>) => {
  // We copy the props in an attribute object
  // in order to remove the properties we want to assign manually.
  // As we don't know which attribute can be applied, we have to rely on any type

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const attributes: any = Object.assign({}, props);
  delete attributes.className;
  delete attributes.to;
  delete attributes.children;
  const { to, children, className } = props;

  const shouldOpenInSameTab = (to: CMSLinkProps) => {
    return (
      to.url.startsWith('#') ||
      SAME_TAB_EXTERNAL_DOMAINS.some((domain: string) =>
        to.url.includes(domain),
      ) ||
      (to.type === 'download' && !to.url.endsWith('.pdf')) ||
      to.type === 'phoneNumber'
    );
  };

  if (!to) {
    console.warn(
      `Warning: Bad Link via ${JSON.stringify({
        to,
        className,
      })} at ref ${ref}`,
    );
    return null;
  }

  if (to.type === 'internal' && typeof to.url !== 'undefined') {
    const href = to.hash ? `${to.url}${to.hash}` : to.url;
    return (
      <NextLink href={href} {...attributes} className={className} ref={ref}>
        {children}
      </NextLink>
    );
  } else if (
    to.type === 'external' ||
    to.type === 'download' ||
    to.type === 'phoneNumber'
  ) {
    return (
      <a
        href={to.url}
        {...attributes}
        className={className}
        // links to app.frame.io and accounts.frame.io should open in the same window
        target={shouldOpenInSameTab(to) ? '_self' : '_blank'}
        rel="noreferrer"
        ref={ref}
      >
        {children}
      </a>
    );
  }

  return null;
};

export default forwardRef<HTMLElement, LinkProps>(Link);
