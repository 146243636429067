'use client';
// TODO: remove react-inlinesvg to use this component on the server
import { ForwardedRef, forwardRef } from 'react';
import SVG from 'react-inlinesvg';

import { SvgProps } from './Svg.types';

const Svg = (
  { className, source, preserveAspectRatio = 'xMinYMid meet' }: SvgProps,
  ref: ForwardedRef<SVGElement>,
) => {
  const { markupString, alt, name } = source;

  return !markupString ? null : (
    <SVG
      className={className}
      innerRef={ref}
      src={markupString.replace(
        '<svg',
        `<svg preserveAspectRatio="${preserveAspectRatio}" role="img"`,
      )}
      title={alt || name}
      width="100%"
      height="100%"
    />
  );
};

export default forwardRef(Svg);
